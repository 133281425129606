import { useEffect, useMemo, useState } from "react";

import { styled, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import SmsIcon from "@mui/icons-material/Sms";
import { LoadingButton } from "@mui/lab";
import { DateTime } from "luxon";

import MemberType from "common/types/MemberType";
import { useSendJoinVideoCallMessageMutation } from "common/services/MemberEngagementService";
import { setAnswer, setSelectedSection } from "common/redux/VisitsSlice";
import { RootState, useAppDispatch } from "common/redux";

import { blue, gray } from "common/styling/colors";
import { Check, Edit, Replay } from "@mui/icons-material";
import { CustomTooltip } from "../../../styling/StyleComponents";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import CareFlowFieldIdsEnum from "common/enums/Calendaring/Visits/CareFlowFieldIdsEnum";
import { isFalsy } from "common/helpers/helpers";
import {
  GoalAssessmentStatusEnum
} from "common/types/GoalType";
import { useGetCalendarEventsQuery } from "common/services/CalendarService";
import AppointmentTypeEnum from "common/enums/Calendaring/Appointments/AppointmentTypeEnum";
import { Flexbox } from "../../../styling/NewStyleComponents";
import StyledIconButton from "../../../components/Button/StyledIconButton";
import {
  CareFlowSectionsEnum,
  CareFlowSubsectionsEnum
} from "common/enums/Calendaring/Visits/CareFlowSectionsEnum";
import { isDisabled, parseGoalAssessmentString } from "../VisitHelper";
import { RIQSnackbar } from "../../../components/Snackbar/Snackbar";
import VisitMotivationTypesEnum from "common/enums/Calendaring/Visits/VisitMotivationTypesEnum";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  margin: 10px 0px; 0px; 0px;
`;

const BubbleContainer = styled("div")`
  display: flex;
  align-self: start;
  background-color: #007aff;
  border-radius: 5px;
  padding: 10px;
  margin-left: 50px;
  width: 400px;
`;

interface IProps {
  member: MemberType;
  visit: GetVisitResponseType;
}

const MAX_CHARS = 800;

const VisitFieldNotConnectedCareMessage = ({ member, visit }: IProps) => {
  const dispatch = useAppDispatch();

  const { answers } = useSelector((state: RootState) => state.visits);

  const [sentSMS, setSentSMS] = useState<boolean>(null);
  const [message, setMessage] = useState<string>(undefined);
  const [recommendedMessage, setRecommendedMessage] =
    useState<string>(undefined);
  const [sendJoinVideoCallMessageMutation, { isLoading, isSuccess }] =
    useSendJoinVideoCallMessageMutation();

  const [editMode, setEditMode] = useState<boolean>(false);

  const resetMessage = () => {
    setMessage(recommendedMessage);
  };

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  useEffect(() => {
    if (isSuccess) setSnackbarOpen(true);
  }, [isSuccess]);

  const handleSmsText = async () => {
    await sendJoinVideoCallMessageMutation({
      member_id: member?.patient?.patient_id,
      action: "CARE_MESSAGE",
      content: message
    });

    dispatch(
      setAnswer({
        id: CareFlowFieldIdsEnum.POST_CALL_CARE_MESSAGE_ORIGINAL,
        value: recommendedMessage
      })
    );
    dispatch(
      setAnswer({
        id: CareFlowFieldIdsEnum.POST_CALL_CARE_MESSAGE_SENT,
        value: message
      })
    );
  };

  const now = useMemo(() => DateTime.now(), []);

  const { data: nextNurseApptData, isLoading: nextNurseApptIsLoading } =
    useGetCalendarEventsQuery(
      {
        patient_id: member?.patient?.patient_id,
        // Get next appt that's any upcoming appointments for today
        startdate: now.plus({ days: 1 }).startOf("day"),
        // Search for the next month
        enddate: now.plus({ months: 1 }).endOf("day"),
        appointment_type: AppointmentTypeEnum.NURSE_FOLLOWUP
      },
      { skip: isFalsy(member) }
    );

  useEffect(() => {
    // If there is already a sent message, set this as message and exit
    if (!isFalsy(answers[CareFlowFieldIdsEnum.POST_CALL_CARE_MESSAGE_SENT])) {
      setMessage(
        `${answers[CareFlowFieldIdsEnum.POST_CALL_CARE_MESSAGE_SENT]}`
      );
      setSentSMS(true);
      return;
    }

    // Assemble the recommended message
    let assembledMessage = "";
    // Intro
    const memberFirstName = member?.patient?.first ?? "";
    const nurseFirstName = visit?.staff?.first
      ? `Nurse ${visit?.staff?.first}`
      : "a nurse";
    assembledMessage += `Hello ${memberFirstName}, this is ${nurseFirstName} with CopilotIQ. I tried calling but we weren't able to connect. I've reviewed your chart. `;

    // Get Goal Assessments
    const goalAssessments = parseGoalAssessmentString({
      input: answers[CareFlowFieldIdsEnum.GOAL_MANAGEMENT] as string
    });
    let isOffTrack = false
    Object.keys(goalAssessments).forEach(key => {
      const goalAssessment = goalAssessments[key];
      if (goalAssessment?.assessment_status !== GoalAssessmentStatusEnum.ON_TRACK){
        isOffTrack = true;
      }
    })

    if (visit.motivation_reason === VisitMotivationTypesEnum.URGENT_ALERT) {
      assembledMessage += `I noticed your readings were off track. Please ensure you continue to monitor and take your medications as prescribed by your provider. `;
    } else {
      if (!isOffTrack)
        assembledMessage += `It's great to see your progress! Please continue your current plan. `;
      else
        assembledMessage += `I've updated your care plan to go over ways we can help you reach your goals. `;
    }
    // Next Steps
    const nextNurseAppt = isFalsy(nextNurseApptData)
      ? null
      : nextNurseApptData[0];
    if (isFalsy(nextNurseAppt)) {
      assembledMessage += `I see you don't have a next appointment scheduled. I'll schedule one for you now.`;
    } else {
      const date = DateTime.fromISO(nextNurseAppt.startdate).setZone(
        member?.patient?.timezone
      );
      const timeRange = `${date.toFormat("h:mm")}-${date.plus({ hours: 1 }).toFormat("h:mm a ZZZZ")}`;
      // If this is an OnDemand Appointment, change the wording as this will be a float nurse sending the message
      if (visit.motivation_reason === VisitMotivationTypesEnum.APPOINTMENT)
        assembledMessage += `Let's discuss my review at your next scheduled appt on ${date.toFormat("MM/dd/yyyy")} ${timeRange}.`;
      else
        assembledMessage += `Your next scheduled appt is on ${date.toFormat("MM/dd/yyyy")} ${timeRange}.`;
    }

    setMessage(assembledMessage);
    setRecommendedMessage(assembledMessage);
  }, [answers, nextNurseApptData]);

  const disabled = isDisabled(visit);

  return (
    <Container>
      <RIQSnackbar
        open={snackbarOpen}
        severity={"success"}
        handleClose={() => setSnackbarOpen(false)}
        content={`Sent text message!`}
      />
      <Typography variant="body1">
        Please review the following recommended message then click{" "}
        <b>{`'Send Text'`}</b> to send the member a care message.
      </Typography>
      <br />
      <Typography variant="body1">
        {`This is a recommended message based off your assessment the member's
        progress toward clinical goals in `}
        <Typography
          variant="caption"
          color={"primary"}
          style={{ cursor: "pointer", fontSize: "100%" }}
          onClick={() =>
            dispatch(
              setSelectedSection({
                section_id: CareFlowSectionsEnum.PRE_CALL,
                subsection_id: CareFlowSubsectionsEnum.PRE_CALL_REVIEW_READINGS
              })
            )
          }
        >
          <b>Pre-Call - Review Care Plan: Readings</b>
        </Typography>
      </Typography>
      <br />
      <Flexbox gap={"15px"} flexDirection={"column"}>
        {sentSMS && !isLoading ? (
          <Typography variant="body1">Sent the below message:</Typography>
        ) : (
          <br />
        )}
        <Flexbox flexDirection={"row"} gap={"10px"}>
          {editMode ? (
            <Flexbox width={"425px"} ml={"50px"} flexDirection={"column"}>
              <TextField
                fullWidth
                multiline
                rows={6}
                placeholder={"Add text to send a care message"}
                label={"Care Message"}
                aria-label={"edit-message"}
                value={message ?? ""}
                disabled={disabled}
                slotProps={{ htmlInput: { maxLength: MAX_CHARS } }}
                sx={{
                  input: {
                    color: gray[900]
                  }
                }}
                onChange={(event) => setMessage(event.target.value)}
              />
              <Typography variant="body2" textAlign={"end"}>
                {message.length} / {MAX_CHARS}
              </Typography>
            </Flexbox>
          ) : (
            <>
              {isFalsy(message) ? (
                <BubbleContainer sx={{ backgroundColor: "white" }}>
                  <Typography variant="body1" sx={{ fontStyle: "italic" }}>
                    Add text to send a care message
                  </Typography>
                </BubbleContainer>
              ) : (
                <BubbleContainer>
                  <Typography color="white">{message}</Typography>
                </BubbleContainer>
              )}
            </>
          )}

          <Flexbox flexDirection={"column"} gap={"10px"}>
            {!editMode ? (
              <StyledIconButton
                onClick={() => setEditMode(true)}
                Icon={Edit}
                disabled={disabled || sentSMS}
                iconColor={blue[700]}
                color={"transparent"}
                size={"small"}
              />
            ) : (
              <br />
            )}

            <StyledIconButton
              onClick={() => resetMessage()}
              Icon={Replay}
              disabled={disabled || sentSMS}
              iconColor={blue[700]}
              color={"transparent"}
              size={"small"}
            />
            {editMode && (
              <>
                <br />
                <br />
                <StyledIconButton
                  onClick={() => setEditMode(false)}
                  Icon={Check}
                  iconColor={blue[700]}
                  color={"transparent"}
                  size={"small"}
                />
              </>
            )}
          </Flexbox>
        </Flexbox>

        <Flexbox>
          <CustomTooltip
            title={
              "Click to automatically send the above care message to the member"
            }
            placement="bottom"
          >
            <div>
              <LoadingButton
                variant="contained"
                loading={isLoading}
                disabled={
                  disabled ||
                  nextNurseApptIsLoading ||
                  sentSMS ||
                  editMode ||
                  isFalsy(message)
                }
                onClick={() => {
                  setSentSMS(true);
                  handleSmsText();
                }}
                startIcon={<SmsIcon />}
              >
                Send Text
              </LoadingButton>
            </div>
          </CustomTooltip>
        </Flexbox>
      </Flexbox>
    </Container>
  );
};

export default VisitFieldNotConnectedCareMessage;
